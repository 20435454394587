<template>
    <v-row justify="center" class="shepherd-start-1">
        <v-col xl="9">
            <v-banner v-if="$route.query && $route.query.invitation_target" class="mb-5 shepherd-space-banner" single-line>
                <v-avatar slot="icon" color="white" size="50">
                    <v-icon large color="success">check_circle</v-icon>
                </v-avatar>
                <span class="subtitle-1 font-weight-medium">{{ invitationText }}</span>
                <template v-if="!isOrgInvite" v-slot:actions>
                    <v-btn @click="$router.push({ path: $route.query.invitation_target })" color="primary" elevation="0">Open space</v-btn>
                </template>
            </v-banner>
            <v-snackbar v-if="validOrgRequests.length" class="dashboardNotifications" v-model="notificationsSnackbar" absolute bottom left :timeout="-1">
                <div class="d-flex justify-space-between align-center">
                    <v-subheader class="secondary--text">Important Notifications</v-subheader>
                    <v-btn @click="notificationsSnackbar = false" small color="secondary" icon><v-icon>clear</v-icon></v-btn>
                </div>
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon dark color="secondary">notification_important</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title class="secondary--text">{{ validOrgRequests.length }} requests require approval</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-btn :to="{ name: 'user-requests' }" color="primary" small>Review</v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-snackbar>
            <v-card flat>
                <v-card-title>
                    <div class="d-flex justify-space-between align-center w-100">
                        <span class="font-weight-bold secondary--text">Dashboard</span>
                        <div class="d-flex align-center">
                            <v-btn v-if="!isPWA()" text color="secondary" @click="installPWA()">
                                <v-icon left>mdi-lightning-bolt</v-icon>
                                <span class="overline font-weight-bold">Install desktop app</span>
                            </v-btn>
                            <v-dialog v-model="pwaDialog" width="500">
                                <v-card>
                                    <v-alert prominent type="info">
                                        <h3 class="text-h6 mb-2">Browser not supported</h3>
                                        <p>
                                            To install the desktop application, please log into Nuvolos using Google Chrome or Microsoft Edge, the current
                                            browser does not support this functionality.
                                        </p>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn text @click="pwaDialog = false">Close</v-btn>
                                        </v-card-actions>
                                    </v-alert>
                                </v-card>
                            </v-dialog>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn class="d-flex align-center" text color="secondary" v-on="on" href="https://docs.nuvolos.cloud" target="_blank">
                                        <v-icon small left>menu_book</v-icon>
                                        <span class="overline font-weight-bold">Getting started</span>
                                    </v-btn>
                                </template>
                                <span>Read the documentation on how to get started with Nuvolos</span>
                            </v-tooltip>
                            <v-menu :close-on-content-click="true" offset-y bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" color="secondary" icon dark class="ml-1" :loading="fetchingOrgContacts">
                                        <v-icon>settings</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense nav>
                                    <v-list-item v-if="isOrgManager === true" :to="{ name: 'home-org-config' }">
                                        <v-list-item-title>
                                            <div class="d-flex align-center">
                                                <v-icon small left>settings</v-icon>
                                                Organization settings
                                            </div>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="isOrgManager === true" :to="{ name: 'home-org-users' }">
                                        <v-list-item-title>
                                            <div class="d-flex align-center">
                                                <v-icon small left>mdi-account-group-outline</v-icon>
                                                Organization users
                                            </div>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        :to="{
                                            name: 'home-org-tokens'
                                        }">
                                        <v-list-item-title>
                                            <div class="d-flex align-center">
                                                <v-icon color="secondary" left small>mdi-key</v-icon>
                                                Organization tokens
                                            </div>
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                    <v-list v-if="orgContacts.length === 0">
                                        <v-list-item class="pr-10 opacity-7">
                                            <v-list-item-icon>
                                                <v-icon>mdi-alert-circle-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>No contacts found</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                    <v-list v-else-if="$isError(orgContacts)" subheader>
                                        <v-subheader>Organization managers</v-subheader>
                                        <v-list-item class="pr-10 warning--text opacity-7">
                                            <v-list-item-icon>
                                                <v-icon color="warning">mdi-alert-circle-outline</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>Error during contact retreval</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                    <v-list two-line subheader v-else>
                                        <v-subheader>Organization managers</v-subheader>
                                        <v-list-item v-for="({ full_name, email }, j) in orgContacts" :key="`contact-${j}`">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ full_name }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <copy-to-clipboard :textToCopy="email" :iconButton="true"></copy-to-clipboard>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                    <v-divider class="mb-2"></v-divider>
                                    <LeaveDialog levelLeavingTitle="Leave Organization" :hierarchicalLevel="nuvolosObjectTypes.ORGANIZATION" :listItem="true" />
                                </v-list>
                            </v-menu>
                        </div>
                    </div>
                </v-card-title>
                <HomeDashboardCard icon="rocket_launch" subTitle="Quick Access" class="shepherd-recent-apps">
                    <div class="mx-n6" v-if="!$isError(recentApps)">
                        <v-row class="mt-4">
                            <v-col xs="12" sm="12" md="4" v-for="(item, idx) in recentApps" :key="idx">
                                <div class="px-6">
                                    <dashboard-quick-access-card :item="item" :loading="fetchingApps" />
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="!fetchingApps && !recentApps.length && !$isError(recentApps)" class="mt-4">
                        <div class="caption text-center">No recent apps, add a course / project first.</div>
                    </div>
                    <div v-if="$isError(recentApps)" class="mt-10">
                        <dashboard-error />
                    </div>
                </HomeDashboardCard>
                <v-row class="mt-10 pt-12 shepherd-spaces">
                    <v-col xs="12" sm="12" md="4">
                        <HomeDashboardCard icon="school" subTitle="Recent Courses">
                            <div v-if="!fetchingSpaces">
                                <div v-for="(item, idx) in recentEducationSpaces" :key="idx" class="my-6">
                                    <dashboard-space-card :item="item" />
                                </div>
                                <div v-if="educationSpaces.length > 2" class="d-flex flex-column justify-start align-center shepherd-create-course">
                                    <router-link
                                        :to="{ name: 'home-add-class', params: { oid: $route.params.oid } }"
                                        style="text-decoration: none"
                                        v-if="isOrgManager || isOrgFaculty">
                                        <div class="d-flex justify-center align-center">
                                            <v-icon small>add</v-icon>
                                            <span class="secondary--text">New Course</span>
                                        </div>
                                    </router-link>
                                    <router-link
                                        v-if="educationSpaces.length > 3"
                                        :to="{ name: 'home-education-spaces', params: { oid: $route.params.oid } }"
                                        style="text-decoration: none"
                                        class="mt-2">
                                        <div class="d-flex justify-center align-center">
                                            <v-icon small>list</v-icon>
                                            <span class="secondary--text ml-1">All Courses</span>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                            <div v-else>
                                <div
                                    v-for="item in [
                                        { id: 'c1', opacity: 1 },
                                        { id: 'c2', opacity: 0.75 },
                                        { id: 'c3', opacity: 0.5 }
                                    ]"
                                    :key="item.id"
                                    class="my-6">
                                    <div :style="{ opacity: item.opacity }">
                                        <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto" />
                                    </div>
                                </div>
                            </div>
                            <div class="mt-6 pa-0 py-n2" v-if="recentEducationSpaces.length < 3 && (isOrgManager || isOrgFaculty) && !fetchingSpaces">
                                <v-list nav class="pa-0">
                                    <v-list-item @click="createSpaceAndApp('class')" class="py-2 shepherd-create-course">
                                        <v-list-item-avatar>
                                            <v-icon x-large>add</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <h4 class="mr-2 secondary--text">New Course</h4>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </div>
                            <div v-if="!recentEducationSpaces.length && !isOrgManager && !isOrgFaculty && !fetchingSpaces" class="mt-4">
                                <div class="caption text-center">No courses to display.</div>
                            </div>
                        </HomeDashboardCard>
                    </v-col>
                    <v-col xs="12" sm="12" md="4">
                        <HomeDashboardCard icon="mdi-beaker" subTitle="Recent Projects">
                            <template v-if="!fetchingSpaces">
                                <div v-for="(item, idx) in recentResearchSpaces" :key="idx" class="my-6">
                                    <dashboard-space-card :item="item" />
                                </div>
                                <div v-if="researchSpaces.length > 2" class="d-flex flex-column align-center justify-start shepherd-create-project">
                                    <router-link
                                        :to="{ name: 'home-add-research-project', params: { oid: $route.params.oid } }"
                                        style="text-decoration: none"
                                        v-if="isOrgManager || isOrgFaculty">
                                        <div class="d-flex justify-center align-center">
                                            <v-icon small>add</v-icon>
                                            <span class="secondary--text">New Research Project</span>
                                        </div>
                                    </router-link>
                                    <router-link
                                        v-if="researchSpaces.length > 3"
                                        :to="{ name: 'home-research-spaces', params: { oid: $route.params.oid } }"
                                        style="text-decoration: none"
                                        class="mt-2">
                                        <div class="d-flex align-center">
                                            <v-icon small>list</v-icon>
                                            <span class="secondary--text ml-1">All Research Projects</span>
                                        </div>
                                    </router-link>
                                </div>
                            </template>
                            <template v-else>
                                <div
                                    v-for="item in [
                                        { id: 'r1', opacity: 1 },
                                        { id: 'r2', opacity: 0.75 },
                                        { id: 'r3', opacity: 0.5 }
                                    ]"
                                    :key="item.id"
                                    class="my-6">
                                    <div :style="{ opacity: item.opacity }">
                                        <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto" />
                                    </div>
                                </div>
                            </template>

                            <div class="mt-6 pa-0 py-n2" v-if="recentResearchSpaces.length < 3 && (isOrgManager || isOrgFaculty) && !fetchingSpaces">
                                <v-list nav class="pa-0">
                                    <v-list-item @click="createSpaceAndApp('research')" class="py-2 shepherd-create-project">
                                        <v-list-item-avatar>
                                            <v-icon x-large>add</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <h4 class="mr-2 secondary--text">New Research Project</h4>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </div>
                            <div v-if="!recentResearchSpaces.length && !isOrgManager && !isOrgFaculty && !fetchingSpaces" class="mt-4">
                                <div class="caption text-center">No research spaces to display.</div>
                            </div>
                        </HomeDashboardCard>
                    </v-col>
                    <v-col xs="12" sm="12" md="4">
                        <HomeDashboardCard icon="mdi-database" subTitle="Recent Datasets">
                            <div v-if="!fetchingSpaces">
                                <div v-for="(item, idx) in recentDatasetSpaces" :key="idx" class="my-6">
                                    <dashboard-space-card :item="item" />
                                </div>
                                <div v-if="datasetSpaces.length > 2" class="d-flex flex-column align-center justify-start">
                                    <router-link
                                        :to="{ name: 'home-add-dataset', params: { oid: $route.params.oid } }"
                                        style="text-decoration: none"
                                        v-if="isOrgManager || isOrgFaculty">
                                        <div class="d-flex align-center">
                                            <v-icon small>add</v-icon>
                                            <span class="secondary--text">New Dataset</span>
                                        </div>
                                    </router-link>
                                    <router-link
                                        v-if="datasetSpaces.length > 3"
                                        :to="{ name: 'home-dataset-spaces', params: { oid: $route.params.oid } }"
                                        style="text-decoration: none"
                                        class="mt-2">
                                        <div class="d-flex align-center">
                                            <v-icon small>list</v-icon>
                                            <span class="secondary--text ml-1">All Datasets</span>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                            <div v-else>
                                <div
                                    v-for="item in [
                                        { id: 'd1', opacity: 1 },
                                        { id: 'd2', opacity: 0.75 },
                                        { id: 'd3', opacity: 0.5 }
                                    ]"
                                    :key="item.id"
                                    class="my-6">
                                    <div :style="{ opacity: item.opacity }">
                                        <v-skeleton-loader type="list-item-avatar-two-line" class="mx-auto" />
                                    </div>
                                </div>
                            </div>
                            <div class="mt-6 pa-0 py-n2" v-if="recentDatasetSpaces.length < 3 && (isOrgManager || isOrgFaculty) && !fetchingSpaces">
                                <v-list nav class="pa-0">
                                    <v-list-item @click="createSpaceAndApp('dataset')" class="py-2">
                                        <v-list-item-avatar>
                                            <v-icon x-large>add</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <h4 class="mr-2 secondary--text">New Dataset</h4>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </div>
                            <div v-if="!recentDatasetSpaces.length && !isOrgManager && !fetchingSpaces" class="mt-4">
                                <div class="caption text-center" v-if="!userInfo.is_trial_user">
                                    No datasets enabled, ask your organization administrator to onboard a dataset.
                                </div>
                                <div class="caption text-center" v-else>There are no datasets enabled for the trial</div>
                            </div>
                        </HomeDashboardCard>
                    </v-col>
                </v-row>
                <TheOrgSelectorDialog v-if="!userMetadata.latestOrg" />
                <v-dialog persistent v-model="showWelcomeDialog" max-width="450px">
                    <v-card>
                        <v-card-title class="justify-center text-h6">Your quick start guide</v-card-title>
                        <v-card-text>
                            <p>Hi {{ userInfo && userInfo.given_name }},</p>
                            <p>
                                We appreciate your interest in Nuvolos! Check out this quick tour to familiarise yourself with the interface. Don't hesitate to
                                contact the Nuvolos team via
                                <v-btn color="primary" fab x-small class="mx-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="messenger" viewBox="0 0 28 32">
                                        <path
                                            d="M28 32s-4.714-1.855-8.527-3.34H3.437C1.54 28.66 0 27.026 0 25.013V3.644C0 1.633 1.54 0 3.437 0h21.125c1.898 0 3.437 1.632 3.437 3.645v18.404H28V32zm-4.139-11.982a.88.88 0 00-1.292-.105c-.03.026-3.015 2.681-8.57 2.681-5.486 0-8.517-2.636-8.571-2.684a.88.88 0 00-1.29.107 1.01 1.01 0 00-.219.708.992.992 0 00.318.664c.142.128 3.537 3.15 9.762 3.15 6.226 0 9.621-3.022 9.763-3.15a.992.992 0 00.317-.664 1.01 1.01 0 00-.218-.707z"></path>
                                    </svg>
                                </v-btn>
                                Messenger.
                            </p>
                        </v-card-text>
                        <v-card-text class="d-flex flex-column align-center">
                            <v-btn color="primary" class="width-8" @click="startTour()">Start</v-btn>
                            <v-btn plain class="mt-3" @click="skipWelcomeTour()">Skip</v-btn>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { truncateText, isAdminRole } from '@/utils'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
import { enumsData } from '@/mixins/enums'
import Shepherd from 'shepherd.js'
import pwa from '@/mixins/pwa'
import '@/sass/signup.scss'

const HomeDashboardCard = () => import('../components/HomeDashboardCard')
const DashboardSpaceCard = () => import('../components/DashboardSpaceCard')
const DashboardQuickAccessCard = () => import('../components/DashboardQuickAccessCard')
const DashboardError = () => import('@/components/DashboardError.vue')
const TheOrgSelectorDialog = () => import('@/components/TheOrgSelectorDialog')
const LeaveDialog = () => import('@/components/LeaveDialog')
const CopyToClipboard = () => import('@/components/CopyToClipboard.vue')

export default {
    components: { HomeDashboardCard, TheOrgSelectorDialog, LeaveDialog, DashboardSpaceCard, DashboardQuickAccessCard, DashboardError, CopyToClipboard },
    mixins: [appTypeAndImageLink, enumsData, pwa],
    data() {
        return {
            notificationsSnackbar: true,
            welcomeTour: null,
            trialTour: null,
            showWelcomeDialog: false,
            pwaDialog: false,
            tourEvents: ['complete', 'close', 'cancel'],
            isUserManagerLevel: null,
            orgContacts: [],
            fetchingOrgContacts: false
        }
    },
    methods: {
        async getOrgContacts() {
            if (!this.defaultResourcePool?.rpid) return
            this.fetchingOrgContacts = true
            try {
                const { data } = await this.$axios.get(`/resource/${this.defaultResourcePool.rpid}/org/${this.$route.params.oid}/contacts`)
                this.orgContacts = data
            } catch (error) {
                this.orgContacts = error
            }
            this.fetchingOrgContacts = false
        },
        truncateText: function (text, length) {
            return truncateText(text, length)
        },
        createSpaceAndApp(type) {
            this.$store.dispatch('homeStore/updateCreateSpaceAndApp', {
                operationSelectedVal: true,
                stepVal: 1,
                spaceTypeVal: type
            })
            if (type === 'class') {
                this.$router.push({
                    name: 'home-add-class',
                    params: { oid: this.$route.params.oid }
                })
            } else if (type === 'research') {
                this.$router.push({
                    name: 'home-add-research-project',
                    params: { oid: this.$route.params.oid }
                })
            } else if (type === 'dataset') {
                this.$router.push({
                    name: 'home-add-dataset',
                    params: { oid: this.$route.params.oid }
                })
            }
        },
        fetchRequests() {
            const spacesWithAdminRole = this.spacesWithPrimarySnapshots.filter(space => isAdminRole(space.space_role))
            if (this.isOrgManager || spacesWithAdminRole.length) {
                this.$store.dispatch('userStore/fetchUserToApproveRequests')
            }
        },
        startTour() {
            const basicTrial = this.trialInfo?.tier === this.trialTiers.FREE
            basicTrial ? this.startTrialTour() : this.startWelcomeTour()
        },
        startTrialTour() {
            this.showWelcomeDialog = false
            this.trialTour = new Shepherd.Tour({
                useModalOverlay: true,
                defaultStepOptions: {
                    cancelIcon: {
                        enabled: true
                    }
                }
            })
            this.trialTour.addStep({
                title: 'Applications',
                text: 'To start an application, click on the quick launch button.',
                attachTo: {
                    element: '.shepherd-quicklaunch',
                    on: 'top'
                },
                buttons: [
                    {
                        action() {
                            return this.complete()
                        },
                        text: 'Next'
                    }
                ]
            })
            setTimeout(() => {
                this.trialTour.start()
            }, 300)
        },
        startWelcomeTour() {
            this.showWelcomeDialog = false
            this.welcomeTour = new Shepherd.Tour({
                useModalOverlay: true,
                defaultStepOptions: {
                    cancelIcon: {
                        enabled: true
                    },
                    scrollTo: { behavior: 'smooth', block: 'center' }
                }
            })
            let step3 = null
            this.isUserManagerLevel = this.isOrgManager || this.isOrgFaculty
            const self = this
            if (this.isUserManagerLevel) {
                step3 = {
                    element: '.shepherd-create-course',
                    title: 'Creating courses',
                    text: `You can create new courses here.`
                }
            } else {
                step3 = {
                    element: '.shepherd-space-banner',
                    title: 'Go to space!',
                    text: `You can navigate to the space you got invited to, by hitting 'open space'.`
                }
            }
            this.welcomeTour.addSteps([
                {
                    title: 'Applications',
                    text: `Nuvolos is a working environment in the cloud. Recent or relevant applications to work in can be accessed here.`,
                    attachTo: {
                        element: '.shepherd-recent-apps',
                        on: 'top'
                    },
                    buttons: [
                        {
                            action() {
                                return this.next()
                            },
                            text: 'Next'
                        }
                    ],
                    id: 'step-1'
                },
                {
                    title: 'Spaces',
                    text: `Work is organized in spaces, which can be courses, research projects or datasets. Each space is independent of each other, but objects (files, tables, applications) can be shared across them.`,
                    attachTo: {
                        element: '.shepherd-spaces',
                        on: 'top'
                    },
                    buttons: [
                        {
                            action() {
                                return this.back()
                            },
                            classes: 'shepherd-button-primary',
                            text: 'Back'
                        },
                        {
                            action() {
                                return this.next()
                            },
                            text: 'Next'
                        }
                    ],
                    id: 'step-2'
                },
                {
                    title: step3.title,
                    text: step3.text,
                    attachTo: {
                        element: step3.element,
                        on: 'top'
                    },
                    buttons: [
                        {
                            action() {
                                return this.back()
                            },
                            classes: 'shepherd-button-primary',
                            text: 'Back'
                        },
                        {
                            action() {
                                if (self.isUserManagerLevel) {
                                    return this.next()
                                }
                                return this.complete()
                            },
                            text: self.isUserManagerLevel ? 'Next' : 'Got it!'
                        }
                    ],
                    id: 'step-3'
                }
            ])
            if (this.isUserManagerLevel) {
                this.welcomeTour.addStep({
                    title: 'Creating projects',
                    text: `You can create new projects here.`,
                    attachTo: {
                        element: '.shepherd-create-project',
                        on: 'top'
                    },
                    buttons: [
                        {
                            action() {
                                return this.back()
                            },
                            classes: 'shepherd-button-primary',
                            text: 'Back'
                        },
                        {
                            action() {
                                return this.complete()
                            },
                            text: 'Got it!'
                        }
                    ],
                    id: 'step-4'
                })
            }

            setTimeout(() => {
                this.welcomeTour.start()
            }, 300)
        },
        skipWelcomeTour() {
            this.showWelcomeDialog = false
            this.welcomeTour = null
            this.$store.commit('setUserMetadata', { show_welcome_tour: false, new_user: false })
            window.Intercom('boot', {
                app_id: 'lliic4ws'
            })
        },
        getAppSpaceType(app) {
            if (this.educationSpaces.find(space => space.sid === app.space_id)) {
                return 'Course'
            }
            if (this.researchSpaces.find(space => space.sid === app.space_id)) {
                return 'Project'
            }
            if (this.datasetSpaces.find(space => space.sid === app.space_id)) {
                return 'Dataset'
            }
        },
        installPWA() {
            const isChromium = !!window.chrome
            if (isChromium) {
                this.install()
            } else {
                this.pwaDialog = true
            }
        }
    },
    computed: {
        ...mapState(['userOrgs', 'fetchingOrgs', 'userInfo', 'userMetadata', 'fetchingUserInfo']),
        ...mapState('appStore', ['recentApps', 'fetchingApps']),
        ...mapState('orgStore', [
            'researchSpaces',
            'educationSpaces',
            'datasetSpaces',
            'recentDatasetSpaces',
            'recentResearchSpaces',
            'recentEducationSpaces',
            'spacesWithPrimarySnapshots',
            'fetchingSpaces'
        ]),
        ...mapGetters('orgStore', ['isOrgFaculty', 'isOrgManager']),
        ...mapState(['trialInfo']),
        ...mapState('userStore', ['defaultResourcePool', 'fetchingDefaultResourcePool']),
        validOrgRequests() {
            const requests = this.$store.getters['userStore/validRequests']
            return requests.filter(request => request.oid && request.oid.toString() === this.$route.params.oid.toString())
        },
        isOrgInvite() {
            return this.$route.query.invitation_target.split('/').indexOf('dashboard') > -1
        },
        invitationText() {
            if (this.isOrgInvite) {
                return 'An invitation has recently been accepted and you can now start exploring the organization.'
            } else {
                return 'An invitation has been recently accepted and you can now start exploring the space.'
            }
        },
        allLoaded() {
            return !this.fetchingOrgs && !this.fetchingApps && !this.fetchingSpaces && !this.fetchingUserInfo && this.userMetadata?.show_welcome_tour
        }
    },
    watch: {
        fetchingDefaultResourcePool(fetching) {
            if (!fetching) {
                this.getOrgContacts()
            }
        },
        allLoaded(loaded) {
            if (loaded) {
                this.showWelcomeDialog = true
                window.Intercom('shutdown')
            }
        }
    },
    created() {
        this.fetchRequests()
        this.getOrgContacts()
        this.tourEvents.forEach(event =>
            Shepherd.on(
                event,
                () => {
                    this.skipWelcomeTour()
                },
                'home-dashboard'
            )
        )
        this.$store.dispatch('appStore/fetchNodePools')
    },
    beforeDestroy() {
        this.tourEvents.forEach(event => Shepherd.off(event, 'home-dashboard'))
    }
}
</script>

<style lang="scss" scoped>
.messenger {
    width: 1.4em;

    path {
        fill: white;
    }
}
::v-deep .dashboardNotifications .v-snack__content {
    padding: 0px;
}
</style>
